/* custom */
* {
  --main-bg-color: #0b5ed7;
  --main-bg-color-light: #468cf2;
}

/* get sessions & tasks */
.dashboard_list {
  max-height: 250px;
  /*overflow: auto;*/
}

/* task_obj */
.task_obj > div,
.task_obj > div > div,
#edit_task_obj > div,
#edit_task_obj > div > div,
#task_obj > div,
#task_obj > div > div {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.task_obj > div > div:first-child,
#edit_task_obj > div > div:first-child,
#task_obj > div > div:first-child {
  padding: 2.7rem .75rem 1rem .75rem;
}

/* reaction btns container */
.task_obj > div > div:nth-child(2),
#edit_task_obj > div > div:nth-child(2),
#task_obj > div > div:nth-child(2) {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* reaction btns */
.task_obj > div > div:nth-child(2) div,
#edit_task_obj > div > div:nth-child(2) div,
#task_obj > div > div:nth-child(2) div {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

/* reaction btns */
.task_obj svg, #task_obj #arrowCustom svg,
#edit_task_obj svg, #edit_task_obj #edit_arrowCustom svg,
#task_obj svg, #task_obj #arrowCustom svg {
  fill: var(--main-bg-color-light);
  color: var(--main-bg-color-light);
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.task_obj div[role="listbox"],
#edit_task_obj div[role="listbox"],
#task_obj div[role="listbox"] {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  max-height: 130px;
}

.task_obj div[role="listbox"] > div,
#edit_task_obj div[role="listbox"] > div,
#task_obj div[role="listbox"] > div {
  max-width: 45px;
  border-radius: 10px;
}

.task_obj div[class$="-multiValue"],
#edit_task_obj div[class$="-multiValue"],
#task_obj div[class$="-multiValue"] {
  background-color: var(--main-bg-color);
}

.task_obj div[class$="-multiValue"] svg,
#edit_task_obj div[class$="-multiValue"] svg,
#task_obj div[class$="-multiValue"] svg {
  fill: aquamarine;
  color: aquamarine;
  width: 16px;
  height: 16px;
}

.task_obj div[class$="-multiValue"] > div:last-child:hover,
#edit_task_obj div[class$="-multiValue"] > div:last-child:hover,
#task_obj div[class$="-multiValue"] > div:last-child:hover {
  background: #6ea8fe;
}

.task_obj > div[class$="-menu"],
#edit_task_obj > div[class$="-menu"],
#task_obj > div[class$="-menu"] {
  z-index: 9;
}

.task_obj > div[class$="-menu"] > div,
#edit_task_obj > div[class$="-menu"] > div,
#task_obj > div[class$="-menu"] > div {
  padding: 10px;
}

.task_obj > div[class$="-menu"] > div > div,
#edit_task_obj > div[class$="-menu"] > div > div,
#task_obj > div[class$="-menu"] > div > div {
  background: none;
  cursor: pointer;
}

/* .css-d7l1ni-option - при наведение-фокусе на div */
.task_obj > div[class$="-menu"] > div > div:hover,
#edit_task_obj > div[class$="-menu"] > div > div:hover,
#task_obj > div[class$="-menu"] > div > div:hover {
  background-color: var(--main-bg-color);
}

/* custom toggle arrow */
.taskObjArrow,
#edit_taskObjArrow,
#taskObjArrow {
  z-index: 9;
  width: 100%;
}

/* END task_obj */

/* bootstrap styles */
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}
.bd-mode-toggle {
  z-index: 1500;
}
